// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Label = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #344054;
  margin-bottom: 4px;
`;
