import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//Import all translation files
import translationArabic from "./locales/ar/translation.json";
import translationEnglish from "./locales/en/translation.json";

//Import translation2 file

//---Using translation
// const resources = {
//     en: {
//         translation: translationEnglish,
//     },
//     es: {
//         translation: translationSpanish,
//     },
//     fr: {
//         translation: translationFrench,
//     },
// }

//---Using different namespaces
const resources = {
  en: {
    common: translationEnglish,
  },
  ar: {
    common: translationArabic,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "ar", //default language
});

export default i18next;
